import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => {
  const post = data.markdownRemark
  const title = post.frontmatter.title
  return (
    <Layout>
      <SEO
        title={title}
        description={post.frontmatter.description || post.excerpt}
        keywords=""
      />
      <div className={"container"}>
        <div div className={"blog"}>
          <div className={"title"}>
            <h1 style={{margin: `0`}}>{title}</h1>
          </div>
          <div className={"date"}>
            <p >{post.frontmatter.date}</p>
          </div>
          <div className={"markdown"} style={{margin: `0`}} dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        path
        title
      }
    }
  }
`
